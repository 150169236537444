/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import {SingleSelectionFilter} from './FilterComponents/SingleSelectionFilter/SingleSelectionFilter';
import {MultipleSelectionFilter} from './FilterComponents/MultipleSelectionFilter/MultipleSelectionFilter';
import {ColorFilter} from './FilterComponents/ColorFilter/ColorFilter';
import {FilterModel, FilterType, IFilterModel, MobileFiltersPanelState} from '../../../types/galleryTypes';
import {RangeFilter} from './FilterComponents/RangeFilter/RangeFilter';
import {FiltersHeader} from './FiltersHeader/FiltersHeader';
import {PriceFilterModel} from '../../../models/PriceFilterModel';
import s from './Filters.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {FiltersFooter} from './FiltersFooter/FiltersFooter';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {CategoryTree} from '../../../category/components/CategoryTree/CategoryTree';
import {useStyles} from '@wix/tpa-settings/react';
import {ExpandableOption} from '../ExpandableOption/ExpandableOption';
import {RadioButton, RadioButtonGroup} from 'wix-ui-tpa';
import {useStylesParams} from '../../../stylesParamsContext';
import classNames from 'classnames';
import {classes as stylableClasses} from './Filters.st.css';
import {hasRelevantFilters} from '../GalleryApp/appUtils';

export interface FiltersProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  clearFilters: Function;
  shouldShowClearFiltersButton: boolean;
  closeMobileFiltersModal?: Function;
  modalHeaderId?: string;
  combineFiltersAndSort?: boolean;
}

export const Filters: React.FunctionComponent<FiltersProps & IGalleryGlobalProps & IProvidedTranslationProps> =
  withGlobals(
    withTranslations()(
      ({
        shouldShowClearFiltersButton,
        closeMobileFiltersModal,
        modalHeaderId,
        combineFiltersAndSort,
        clearFilters,
        t,
        globals: {
          filterModels,
          shouldShowMobile,
          isCategoryPage,
          mobileFiltersPanelState,
          shouldShowSort,
          selectedSort,
          sortingOptionsWithoutDefault,
          setSelectedSort,
          totalProducts,
          hasSelectedFilters,
          categories,
        },
      }: FiltersProps & IGalleryGlobalProps & IProvidedTranslationProps) => {
        const styles = useStyles();
        const stylesParams = useStylesParams();

        const getSortingComponent = () => {
          const sortingOptions = (
            <RadioButtonGroup
              className={stylableClasses.radioButtonGroupRoot}
              data-hook="sort-in-mobile-panel"
              value={selectedSort.id}
              name="sort-by"
              withSpacing
              onChange={(optionId) => {
                setSelectedSort(sortingOptionsWithoutDefault.find((option) => option.id === optionId));
              }}>
              {sortingOptionsWithoutDefault.map((option) => (
                <RadioButton label={t(option.titleKey)} value={option.id} key={option.id} />
              ))}
            </RadioButtonGroup>
          );

          return mobileFiltersPanelState === MobileFiltersPanelState.SORT_ONLY ? (
            <li key="sort" data-hook={`filter-type-sort`}>
              {sortingOptions}
            </li>
          ) : (
            <li key="sort" data-hook={`filter-type-sort`}>
              <ExpandableOption title={t('sortByText')} expanded={true}>
                {sortingOptions}
              </ExpandableOption>
            </li>
          );
        };

        const getFilterComponent = (filterModel: FilterModel) => {
          switch (filterModel.filterType) {
            case FilterType.COLLECTION:
              return isCategoryPage ? (
                <MultipleSelectionFilter
                  filterId={filterModel.filterId}
                  selected={[...filterModel.activeOptions]}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              ) : (
                <SingleSelectionFilter
                  selected={filterModel.activeOptions}
                  filterId={filterModel.filterId}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.CUSTOM_COLLECTION:
              return (
                <MultipleSelectionFilter
                  filterId={filterModel.filterId}
                  selected={[...filterModel.activeOptions]}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.COLOR_OPTION:
              return (
                <ColorFilter
                  selected={[...filterModel.activeOptions]}
                  filterId={filterModel.filterId}
                  colorOptions={filterModel.options.map((option) => ({key: option.key, value: option.value}))}
                />
              );
            case FilterType.LIST_OPTION:
              return (
                <MultipleSelectionFilter
                  selected={[...filterModel.activeOptions, ...filterModel.selectedOptions]}
                  filterId={filterModel.filterId}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.PRICE:
              const selectedRange = {
                min: filterModel.activeOptions.minPrice,
                max: filterModel.activeOptions.maxPrice,
              };
              return (
                <RangeFilter
                  filterId={filterModel.filterId}
                  options={filterModel.options}
                  selectedRange={selectedRange}
                  key={`${selectedRange.min}-${selectedRange.max}`}
                />
              );
          }
        };

        const hasActiveOptions = (filterModal: IFilterModel) => {
          switch (filterModal.filterType) {
            case FilterType.COLOR_OPTION:
            case FilterType.CUSTOM_COLLECTION:
            case FilterType.LIST_OPTION:
              return !!filterModal.activeOptions.length;
            case FilterType.PRICE:
              return (
                (filterModal.activeOptions.minPrice &&
                  filterModal.activeOptions.minPrice !== (filterModal as PriceFilterModel).minPrice) ||
                (filterModal.activeOptions.maxPrice &&
                  filterModal.activeOptions.maxPrice !== (filterModal as PriceFilterModel).maxPrice)
              );
          }
        };

        const isExpended = (index: number, filterModel: IFilterModel) => {
          return index === 0 || hasActiveOptions(filterModel);
        };

        const getFilterListView = (currentFilterModels: FilterModel[]) => {
          const shouldRenderSort = combineFiltersAndSort && shouldShowSort;
          const shouldRenderFilter = combineFiltersAndSort
            ? mobileFiltersPanelState === MobileFiltersPanelState.FILTERS_AND_SORT ||
              mobileFiltersPanelState === MobileFiltersPanelState.FILTERS_ONLY
            : hasRelevantFilters(filterModels);

          return (
            <ul data-hook={`filters-list`} className={classNames(s.filterList, {[s.categoryPage]: isCategoryPage})}>
              {shouldRenderSort && getSortingComponent()}
              {shouldRenderFilter &&
                currentFilterModels.map((filterModel: FilterModel, index) => (
                  <li key={filterModel.filterId} data-hook={`filter-type-${filterModel.filterType}`}>
                    <ExpandableOption
                      title={filterTitle(filterModel)}
                      filterType={filterModel.filterType}
                      expanded={isExpended(index, filterModel)}>
                      {getFilterComponent(filterModel)}
                    </ExpandableOption>
                  </li>
                ))}
            </ul>
          );
        };
        const renderFilters = () => {
          return (
            <>
              <FiltersHeader
                closeMobileFiltersModal={closeMobileFiltersModal}
                modalHeaderId={modalHeaderId}
                combineFiltersAndSort={combineFiltersAndSort}
                data-hook={'side-filter-header'}
              />
              {getFilterListView(filterModels)}
              <FiltersFooter
                clearFilters={clearFilters}
                closeMobileFiltersModal={closeMobileFiltersModal}
                shouldShowClearFiltersButton={shouldShowClearFiltersButton}
                filtersPanelState={mobileFiltersPanelState}
              />
            </>
          );
        };

        const filterTitle = (filterModel: FilterModel) => {
          let title = filterModel.title;

          if (!shouldShowMobile) {
            return title;
          }

          if (Array.isArray(filterModel.activeOptions)) {
            const length = filterModel.activeOptions.length;
            if (length > 0) {
              title += ` (${length})`;
            }
          }

          if (filterModel.activeOptions && (filterModel as PriceFilterModel).activeOptions.minPrice) {
            const filterModelAsPrice = filterModel as PriceFilterModel;
            const minPriceFormatted = filterModelAsPrice.options.find(
              (v) => v.key === filterModelAsPrice.activeOptions.minPrice
            ).value;
            const maxPriceFormatted = filterModelAsPrice.options.find(
              (v) => v.key === filterModelAsPrice.activeOptions.maxPrice
            ).value;
            title += ` (${minPriceFormatted} - ${maxPriceFormatted})`;
          }

          return title;
        };

        const shouldRenderCategoryTree =
          isCategoryPage && !shouldShowMobile && styles.get(stylesParams.gallery_showCategories) && categories.length;

        const shouldRenderFilters = () => {
          if (isCategoryPage) {
            return (hasRelevantFilters(filterModels) && (hasSelectedFilters || totalProducts > 0)) || shouldShowMobile;
          } else {
            return true;
          }
        };

        return (
          <section className={classNames(s.filters, {[s.categoryPage]: isCategoryPage})}>
            {shouldRenderCategoryTree ? <CategoryTree /> : undefined}
            {shouldRenderFilters() ? renderFilters() : undefined}
          </section>
        );
      }
    )
  );
